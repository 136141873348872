import "./animated_gif.css"

const AnimatedGif = () => {
    return (
        <div className="animated_gif">
            <img className="snowman" src="/animated/christmas/snowman.gif"></img>
            <img className="tree" src="/animated/christmas/tree.gif"></img>
        </div>
    )
}

export default AnimatedGif;